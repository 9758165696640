<p-table>
  <ng-template pTemplate="caption">
    <div class="table-header">
       <div class="grid">
                <div class="col-12 md:col-6 lg:col-6 header-buttons-left">
                    <a [ngClass]="langDir=='rlt'?'a-content':''" href="{{dashboardPath}}"><i class="pi pi-home"></i> {{'dashboard' | translate}}</a> <a [ngClass]="langDir=='rlt'?'a-content':''" (click)="onRecordsListLinkClicked()"> {{'records' | translate}} </a> <span class="object-slug"> {{recordTitle}} </span>
                </div>
            </div>
    </div>
  </ng-template>
  <ng-template pTemplate="summary">
    <ng-container *ngIf="recordFormData">
      <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="{{'record' | translate}}">

          <div class="grid">
            <div class="col-12 md:col-4">
              <div class="field-label">{{'title' | translate}}</div>
              <input type="text" pInputText [ngStyle]="{'width': '100%'}"  required [(ngModel)]="recordFormData.Record.Title">
            </div>

            <div class="col-12">
              <p-checkbox label="{{'active' | translate}}" [(ngModel)]="recordFormData.Record.ActiveBool"
                          [binary]="true"></p-checkbox>
            </div>
            <div class="col-12">
              <p-checkbox label="{{'allow-fileupload' | translate}}" [(ngModel)]="recordFormData.Record.AllowUploadBool"
                          [binary]="true"></p-checkbox>
            </div>
            <div class="col-12">
              <p-checkbox label="{{'tabed-form' | translate}}" [(ngModel)]="recordFormData.Record.TapedFormBool"
                          [binary]="true"></p-checkbox>
            </div>
            <div class="col-12">
              <p-checkbox label="{{'open-form-in-dialog' | translate}}"
                          [(ngModel)]="recordFormData.Record.OpenFormInDialogBool" [binary]="true"></p-checkbox>
            </div>
            <div class="col-12">
              <p-checkbox label="{{'show-in-menu' | translate}}" [(ngModel)]="recordFormData.Record.ShowInMenuBool"
                          [binary]="true"></p-checkbox>
            </div>
          </div>

          <div class="grid" *ngIf="recordFormData.Record.ShowInMenuBool">
            <div class="col-12 md:col-4">
              <div class="field-label">{{'menu-parent' | translate}}</div>
              <p-dropdown [showClear]="true" [options]="rootMenus" optionLabel="Title" [(ngModel)]="rootMenuSelected"
                          placeholder="select a menu"></p-dropdown>
            </div>
          </div>

          <div class="grid">
            <div class="col-12 md:col-4">
              <div class="field-label">{{'permissions' | translate}}</div>
              <p-multiSelect [options]="groups" optionLabel="Title" [(ngModel)]="groupsSelected"></p-multiSelect>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="{{'form' | translate}}">
          <app-form-builder [formData]="recordFormData.Record"
                            [fieldCollectionService]="fieldCollectionService"
                            [recordSlug]="recordSlug"></app-form-builder>
        </p-tabPanel>
        <p-tabPanel  *ngIf="recordVersions" header="{{'versions' | translate}}">
          <app-edit-record-versions [versions]="recordVersions"></app-edit-record-versions>
        </p-tabPanel>
      </p-tabView>
    </ng-container>
    <div class="footer">
      <app-save-cancel-buttons [disabled]="!(permissions.canEdit || permissions.canCreate)"
        (save)="onSaveClicked($event)"
        (cancel)="onCancelClicked()"
        [showDeleteButton]="!newItem && permissions.canDelete"
        (delete)="onDeleteClicked()"
        ></app-save-cancel-buttons>
    </div>
  </ng-template>
</p-table>

