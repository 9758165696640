
export enum FieldSettingNames {
  Value = 'value',
  Label = 'label',
  Placeholder = 'placeholder',
  DefaultValue = 'defaultvalue',
  IsActive = 'active',
  IsHidden = 'hidden',
  SearchFilter = 'searchfilter',
  ResultList = 'resultlist',
  ShowInItemView = 'onitemview',
  IsRequired = 'required',
  Options = 'options',
  IsMultipleSelect = 'multiple',
  DateFormat = 'dateformat',
  HourFormat = 'timeformat',
  ShowSeconds = 'showseconds',
  UnitType = 'unittype',
  Currency = 'currency',
  NumberType = 'numbertype',
  TextType = 'type',
  MaxLength = 'maxlength',
  UploadMaxSize = 'uploadmaxsize',
  UploadMaxFile = 'uploadmaxfile',
  AcceptFileTypes = 'accepts',
  UploadName = 'uploadname',
  RelationData = 'relationdata',
  RelationType = 'relationtype',
  Record = 'record',
  Model = 'model',
  DisplayFields = 'displayfields',
  FieldMapping = 'fieldmapping',
  ReadOnly = 'readonly',
  Info = 'info',
  Summable = 'summable',
  SummablePrefix = 'summable_prefix',
  SummableSuffix = 'summable_suffix'
}
