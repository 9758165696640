<div class="content-section">
  <div class="forgot-password-body">


    <div class="card forgot-password-card">
      <div class="forgot-password-card-content">
        <div class="forgot-password-card-content-logo">
             <img src="assets/layout/images/logo.png"/>
        </div>
        <div class="forgot-password-card-content-form">
          <h2>Recover Password</h2>
          <p>Please be careful to enter strong password that you have not used before.</p>


          <form [formGroup]="passwordForm">
            <div class="p-field">
              <input id="password" formControlName="password" placeholder="New Password" type="password" pInputText>
              <div *ngIf="passwordForm && (f.password.dirty && f.password.errors?.required)"
                   class="text-danger"> {{ 'password.required' | translate }}
              </div>
            </div>

            <div class="p-field">
              <input id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password"
                     type="password" pInputText>
              <div *ngIf="passwordForm && (f.confirmPassword.dirty && f.confirmPassword.errors?.required)"
                   class="text-danger"> {{ 'password.confirm-required' | translate }}
              </div>
              <div *ngIf="passwordForm && (f.confirmPassword.dirty && f.confirmPassword.errors?.mustMatch)"
                   class="text-danger">{{ 'password.must-match' | translate }}
              </div>
            </div>
          </form>

          <div class="p-field" style="text-align: right; margin-top: 20px;">

            <button pButton type="button" label="Recover" [disabled]="passwordForm.invalid" (click)="send()"></button>
          </div>

        </div>
      </div>
    </div>

  </div>

</div>
