<div class="login-body">
    <div class="card login-panel p-fluid">
        <div class="login-panel-content">
            <div class="grid">
                <div class="col-12 p-col-sm-6 p-col-md-6 logo-container">
                    <img src="assets/layout/images/logo.png"/>
                </div>
                <div *ngIf="showFailedLoginMessage">
                    <span class="error-message">The provided details don't seem to be correct. Please try again.</span>
                </div>
                <div>
             
                </div>
                <div class="col-12 username-container">
                    <!-- <h:panelGroup> -->
                        <label>User Name</label>
                        <div class="login-input">
                            <input id="input" type="text" [(ngModel)]="username" (keydown.enter)="login()"  pInputText>
                        </div>
                    <!-- </h:panelGroup> -->
                </div>
                <div class="col-12 password-container">
                    <!-- <h:panelGroup> -->
                    <label  dir="rtl">Password</label>
                        <div class="login-input">
                            <input type="password"  [(ngModel)]="password" (keydown.enter)="login()" style="width: 100%;"/>
                        </div>
                    <!-- </h:panelGroup> -->
                </div>
                <div class="col-12 sm:col-6 md:col-6 rememberme-container">
                    <p-checkbox></p-checkbox>
                    <label> Remember me</label>
                </div>

                <div class="col-12 sm:col-6 md:col-6 forgetpassword-container">
                    <a routerLink="/forgot-password" class="forgot-password">Forgot Password</a>
                </div>

                <div class="col-12">
                        <button [routerLink]="['/']" (click)="login()" pButton type="button" icon="pi pi-user" label="Sign In"></button>
                </div>
            </div>
        </div>
    </div>
</div>
