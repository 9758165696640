<ng-container>
    <ng-container>
        <div class="grid">
            <div class="col-12 sm:col-4 " *ngFor="let row of widgetList">
                <p-card  *ngIf="row.WidgetData  && row.Type == 'weather'  && widgetList.length > 0" class="temp-box modal-table">
                    <ng-template pTemplate="header">
                    <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                     </div>
                     </ng-template>
                    <div class="temp">
                        <div class="sm:col-8">
                            <h2>{{ row.WidgetData.temperature }} <sup>{{ row.WidgetData.temperatureUnit }}</sup></h2>
                            <h3>{{row.WidgetData.humidity.value }} {{row.WidgetData.humidity.unit }} {{'humidity' | translate}}</h3>
                        </div>
                        <div class="sm:col-4">
                            <div class="temp-image">
                                <img src="{{ row.WidgetData.icon }}" alt="temp-img">
                            </div>
                        </div>
                    </div>
                </p-card>
                <p-card  *ngIf="row.WidgetData && row.Type == 'modelGrid'" class="w-model-box modal-table">
                    <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                            <a align="right" pButton class="p-button-primary p-card-left-btn" icon="pi pi-plus-circle" [routerLink]="['admin', row.WidgetData.Name, 'new-item']"></a>
                            <a align="right" pButton class="p-button-secondary p-card-right-btn" icon="pi pi-search" [routerLink]="['admin', row.WidgetData.Name]"></a>
                        </div>
                    </ng-template>
                    <p-table (onRowSelect)="onModelGridSelect($event,row.WidgetData.Name)" [value]="row.WidgetData.Items" selectionMode="single">
                        <ng-template pTemplate="body" let-product>
                            <tr [pSelectableRow]="product">
                                <td class="modal-table-img"><img [src]="product.avatarURL ==null?'./assets/avatar/avatar-default.png':product.avatarURL"
                                        alt="Image"></td>
                                <td class="modal-first-name">{{product.FirstName}}</td>
                                <td class="modal-last-name">{{product.Surname}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-card>
                <p-card  *ngIf="row.WidgetData && row.Type == 'infoBox'" class="w-info-box modal-table">
                    <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                    <p-table [value]="row.WidgetData.Items" selectionMode="single">
                        <ng-template pTemplate="body" let-product>
                            <tr [pSelectableRow]="product">
                                <td><a href="record/item/{{ row.WidgetData.Slug }}">{{product.ItemData[0].Value}}</a></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-card>
                <p-card  *ngIf="row.WidgetData && row.Type == 'recordGrid'" class="w-record-box modal-table">
                    <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                             <a  pButton class="p-button-primary p-card-left-btn" icon="pi pi-plus-circle"  (click)="onNewRecordItemClicked(row.WidgetData.Slug)"></a>
                             <a  pButton class="p-button-secondary p-card-right-btn" icon="pi pi-search" [routerLink]="['record', 'items', row.WidgetData.Slug]"></a>
                      
                        </div>
                    </ng-template>
                    <p-table [value]="row.WidgetData.Items" selectionMode="single">
                        <ng-template pTemplate="body" let-product>
                            <tr [pSelectableRow]="product"  (click)="onRecordGridRow(product.Slug)">
                                <td *ngIf="product.ItemData[0] && product.ItemData[0].Type != 'UploadField'">{{product.ItemData[0].Value}}</td>
                                <td *ngIf="product.ItemData[0] && product.ItemData[0].Type == 'UploadField'">bild</td>
                                <td *ngIf="product.ItemData[1] && product.ItemData[1].Type != 'UploadField'">{{product.ItemData[1].Value}}</td>
                                <td *ngIf="product.ItemData[1] && product.ItemData[1].Type == 'UploadField'">bild</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-card>
                
                <p-card  *ngIf="row.WidgetData && row.Type == 'taskGrid'" class="w-record-box modal-table">
                    
                 
                    <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                    <p-table [value]="row.WidgetData" selectionMode="single">
                        <ng-template pTemplate="body" let-product>
                            <tr [pSelectableRow]="product"  (click)="onRecordGridRow(product.RecordItemSlug,product.Slug, product.DefinitionSlug)">
                                <td>{{product.Created}}</td>
                                <td>{{product.Action}}</td>
                                <td>{{product.Definition}}</td>
                                <td></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-card>
                <p-card class="w-clock-box modal-table" *ngIf="row.Type == 'clock'">
                    <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                    <cs-canvas-clock></cs-canvas-clock>
                </p-card>
                <p-card *ngIf="row.WidgetData && row.Type == 'calendar'">
                    <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                    <p-calendar [inline]="true" [showWeek]="false" class="w-calendar"></p-calendar>
                </p-card>
                <p-card *ngIf="row.WidgetData && row.Type == 'modelCount'">
                    <div class="w-count-box" [style.background-color]="row.WidgetData.backgroundcolor">{{row.Title}}</div>
                    <div class="w-count-box" [style.background-color]="row.WidgetData.backgroundcolor" [innerHtml]="row.WidgetData.count"></div>
                </p-card>
                <p-card *ngIf="row.WidgetData && row.Type == 'recordCount'">
                    <div class="w-count-box" [style.background-color]="row.WidgetData.backgroundcolor">{{row.Title}}</div>
                    <div class="w-count-box" [style.background-color]="row.WidgetData.backgroundcolor" [innerHtml]="row.WidgetData.count"></div>
                </p-card>
            
                 <p-card  *ngIf="row.WidgetData && row.Type == 'featuredInfoBox'" class="w-info-box modal-table">
                    <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                      <h5>{{row.WidgetData.Title}}</h5>
                </p-card>
                <p-card *ngIf="row.WidgetData && row.Type == 'linearChart'">
                     <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
           
                    <p-chart type="line" [data]="row.WidgetData"></p-chart>
                </p-card>
                <p-card *ngIf="row.WidgetData && row.Type == 'barChart'">
                    <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                    <p-chart type="bar" [data]="row.WidgetData"></p-chart>
                </p-card>
                <p-card *ngIf="row.WidgetData && row.Type == 'pieChart'">
                     <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                    <p-chart type="pie" [data]="row.WidgetData"></p-chart>
                </p-card>
                <p-card *ngIf="row.WidgetData && row.Type == 'polarAreaChart'">
                     <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                    <p-chart type="polarArea" [data]="row.WidgetData"></p-chart>
                </p-card>
                <p-card  *ngIf="row.WidgetData && row.Type == 'doughnutChart'">
                    <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                    <p-chart type="doughnut" [data]="row.WidgetData"></p-chart>
                </p-card>
                <p-card  *ngIf="row.WidgetData && row.Type == 'lastLogin'" class="w-last-login modal-table">
                     <ng-template pTemplate="header">
                        <div class="p-card-title ng-star-inserted center">
                            {{ row.Title }}
                        </div>
                    </ng-template>
                   <h4>Date : {{row.WidgetData.Date | date :'short'}}</h4>
                   <h4>IP : {{row.WidgetData.IP}}</h4>
                   <h4>{{row.WidgetData.Status}}</h4>
                </p-card>
            </div>
        </div>
    </ng-container>
</ng-container>